import React from "react"
import theme from "theme"
import { Theme, Text, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Paintball Martesana</title>
        <meta
          name={"description"}
          content={"Scatena il divertimento, la strategia e l'avventura!"}
        />
        <meta property={"og:title"} content={"Paintball Martesana"} />
        <meta
          property={"og:description"}
          content={"Scatena il divertimento, la strategia e l'avventura!"}
        />
        <meta
          property={"og:image"}
          content={"https://exilovatebix.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://exilovatebix.com/img/324234.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://exilovatebix.com/img/324234.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://exilovatebix.com/img/324234.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://exilovatebix.com/img/324234.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://exilovatebix.com/img/324234.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://exilovatebix.com/img/324234.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h2"
          font="--headline1"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Contattateci
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          Siete pronti a prenotare la vostra prossima partita o avete domande?
          Contattateci a:
          <br />
          <br />
          Sede: Strada Vicinale Della Baraghetta 50, 20060
          <br />
          Numero di telefono: +39 351 594 3446
          <br />
          Email: info@exilovatebix.com
          <br />
          <br />
          Siamo qui per aiutarvi a pianificare la perfetta uscita di paintball.
          Creiamo ricordi indimenticabili a Martesana!
        </Text>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
